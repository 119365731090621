import {HOST_MEDIA_URL_V1} from '../redux/api'
import defaultLogo from '../assets/images/no_image.webp';

export function generateImageMediaUrl(path) {
    if (path?.startsWith('http')) {
        return path;
    }
    return path ? `${HOST_MEDIA_URL_V1}/resources${path}` : defaultLogo;
}

export function generateFileMediaUrl(path) {
    if (path && path?.startsWith('http')){
        return path;
    }
    return path ? `${HOST_MEDIA_URL_V1}/resources${path}` : defaultLogo;
}
