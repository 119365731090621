// import packages
import React from "react";
import {connect} from "react-redux";
import {Link} from 'react-router-dom'

// Import assets
import './footer.scss'

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetContacts} from "../../redux/actions";
import {EmailIcon, Logo, TelephoneIcon} from "../../assets/images";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";


function Footer(props) {
    const {contacts, staticTexts} = props;

    function downloadPresentation(pdf) {

        if (pdf) {
            fetch(generateImageMediaUrl(pdf?.path))
                .then(response => {
                    response.blob().then(blob => {
                        let a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = `${pdf.name}`;
                        a.click();
                    });
                });
        }
    }


    return <footer className='footer-wrapper'>
        <div className="desktop-footer-wrapper">
            <div className="top-part">
                <div className="col">
                    <div className="logo-wrapper">
                        <Logo title={''}/>
                    </div>

                    <a href={`tel:${contacts?.phoneNumber}`} className={'contact-item'}>
                        <TelephoneIcon/>
                        {contacts?.phoneNumber}
                    </a>
                    <a href={`mailto:${contacts?.email}`} className={'contact-item'}>
                        <EmailIcon/>
                        {contacts?.email}
                    </a>

                    {contacts?.presentationFile && <div className={'text-wrapper presentation-button'} onClick={() => {downloadPresentation(contacts?.presentationFile)}}>
                        {staticTexts?.footer_section_button_presentation || 'Ներբեռնեք ներկայացումը'}
                    </div>}
                </div>

                <div className={'right-block'}>
                    <div className="col">
                        <div className="title">{staticTexts?.footer_section_sites_title}</div>
                        <Link to={getUrlWithLocal('/')}>{staticTexts?.footer_section_home}</Link>
                        <Link to={getUrlWithLocal('/about')}>{staticTexts?.footer_section_about}</Link>
                        <Link to={getUrlWithLocal('/services')}>{staticTexts?.footer_section_services}</Link>
                        <Link to={getUrlWithLocal('/info')}>{staticTexts?.footer_section_info}</Link>
                        <Link to={getUrlWithLocal('/contact')}>{staticTexts?.footer_section_contact}</Link>
                    </div>
                    <div className="col">
                        <div className="title">{staticTexts?.footer_section_services}</div>
                        <Link to={getUrlWithLocal('/')} >
                            <span className={'item-description'}>{staticTexts?.footer_services_1}</span>
                        </Link>
                        <Link to={getUrlWithLocal('/')}>
                            <span className={'item-description'}>
                                {staticTexts?.footer_services_2}
                            </span>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="bottom-part">
                <div className="copyright">{staticTexts?.footer_copyright}</div>
                {/*<div className='developed-by'>*/}
                {/*    <div className={'design'}>*/}
                {/*        {staticTexts?.footer_section_developed_by_text} <a href={'https://solidy.am/'} target={"_blank"}>Solidy</a>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    </footer>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'selectedLanguage',
        'services'
    ])
};

const mapDispatchToProps = {GetContacts};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
