import './HomeServices.scss'
import {ArrowLinkIcon, EllipseIcon, ServiceImg} from "../../../assets/images";
import {useEffect, useState} from "react";
import React from "react";
import {Link} from "react-router-dom";
import {getUrlWithLocal} from "../../../utils/getUrlWithLocal";

function HomeServices(props) {
    const {services,staticTexts, block} = props
    const [modalOpen,setModalOpen] = useState(false)
    const [service,setService] = useState('')
    const [date,setDate] = useState('')

    function toggleModalOpen(service){
        setModalOpen(!modalOpen)
        setDate(service)
    }

    useEffect(() => {
        if(modalOpen){
            setService(date)
        }else {
            setService('')
        }
    },[modalOpen])

    return <div className={'homeService-wrapper'}>
        <EllipseIcon className="homeService_ellipse_icon"/>
        <div className={'homeService-sub-title'}>
            {staticTexts?.homepage_services_title}
        </div>
        <div className={'homeService-title'}>
            {services?.title}
        </div>
        <div className={'homeService-description'}>
            {services?.description}
        </div>
        <div className={'homeService-blocks'}>
            <div className="left-part">
                {services?.blocks?.[0] && <Link to={getUrlWithLocal('/services')} className={'homeService-card'}>
                    <div className={'homeService-card-title'}>
                        {staticTexts?.homepage_services_block1_title}
                    </div>
                    <div className={'homeService-card-description'}>{staticTexts?.homepage_services_block1_description}</div>
                    <div className={'homeService-card-more'} onClick={() => toggleModalOpen(services?.blocks[0])}>
                        {staticTexts?.homepage_more_info_btn} <ArrowLinkIcon/>
                    </div>
                </Link>}
                {services?.blocks?.[1] && <Link to={getUrlWithLocal('/services')} className={'homeService-card'}>
                    <div className={'homeService-card-title'}>
                        {staticTexts?.homepage_services_block2_title}
                    </div>
                    <div className={'homeService-card-description'}>
                        {staticTexts?.homepage_services_block2_description}
                    </div>
                    <div className={'homeService-card-more'} onClick={() => toggleModalOpen(services?.blocks[1])}>
                        {staticTexts?.homepage_more_info_btn} <ArrowLinkIcon/>
                    </div>
                </Link>}
            </div>
            <div className="right-part">
                <img src={ServiceImg} alt=""/>
            </div>
        </div>
    </div>
}

export default HomeServices
