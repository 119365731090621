import './product.scss'
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import {PagLeftIcon, PagRightIcon, ShoppingBagIcon} from "../../assets/images";
import ProductCard from "../../components/uiElements/card/productCard/ProductCard";
import {Pagination} from "antd";
import React, {useEffect} from "react";
import {PAGE_PRODUCT_COUNT} from "../../constants/constType";
import {useRef, useState} from "react";
import Subscribe from "../../components/subscribe/Subscribe";
import {GetProducts, isOpen, shopProduct} from "../../redux/actions";
import FavoriteProduct from "../../components/favoriteProduct/FavoriteProduct";

function Product(props){
    const {staticTexts,products,shopProducts} = props
    const [currentPage, setCurrentPage] = useState(1);
    const [favoriteBlock, setFavoriteBlock] = useState(false);
    const [shopProduct,setShopProduct] = useState({
        count:null,
        items:[],
        checkedId:[]
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])
    const onChange = (e,product) => {

        if(e && e.target.checked){
            setShopProduct({
                count: shopProducts?.count + 1,
                items: [product,...shopProducts?.itemsList],
                checkedId:[product?.id,...shopProducts?.checkedId]
            })
        }else {
            setShopProduct({
                count: shopProduct?.items?.length - 1,
                items: shopProduct?.items?.filter(item => item?.id !== product?.id),
                checkedId:shopProducts?.checkedId.filter(item => item !== product?.id)
            })
        }
    };
    useEffect(() => {
        props.shopProduct(shopProduct)
    },[shopProduct])

    const isFetching = useRef(false)

    function toggleFavoriteBlock(){
        setFavoriteBlock(!favoriteBlock)
        props.isOpen(!favoriteBlock)
    }

    useEffect(() => {
        props.GetProducts()
    },[])

    async function onPageChanged (product) {
        let count = product -1
        isFetching.current = true;
        const configInfo = {
            reset: true,
            offset: count * PAGE_PRODUCT_COUNT,
            limit: PAGE_PRODUCT_COUNT,
        }
        await props.GetProducts(configInfo)
        setCurrentPage(product);
        window.scrollTo({
            top: 0
        });
    }

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}>
                <PagLeftIcon className={'arrow_icon'}/>
                <span>
                    {staticTexts?.blog_page_prev}
                </span>
            </a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>
                <span>
                    {staticTexts?.blog_page_next}
                </span>
                <PagRightIcon className={'arrow_icon'}/>
            </a>;
        }
        return originalElement;
    };

    return <div className={`product-wrapper`}>
        <div className={'product-header'}>
            <div className={'product-title'}>
                {staticTexts?.product_page_title}
            </div>
            <div className={'product-description'}>
                {staticTexts?.product_page_description}
            </div>
        </div>
        <div className={'product-block'}>
            {
                products?.itemsList?.length ? products?.itemsList?.map(item => {
                    return <ProductCard product={item}
                                        key={item?.id}
                                        onChange={onChange}/>
                }) : ''
            }
            <div className={'shop-block'} onClick={toggleFavoriteBlock}>
                <div className={'shoppingBag-wrapper'}>
                    {
                        shopProducts?.count ?  <span>{shopProducts?.count}</span> : ''
                    }
                    <ShoppingBagIcon/>
                </div>
            </div>
        </div>
        <Pagination current={currentPage}
                    onChange={onPageChanged}
                    simple={false}
                    total={products?.count}
                    defaultPageSize={15}
                    hideOnSinglePage={true}
                    itemRender={itemRender}
                    className={`pagination`}
        />
        <Subscribe/>
        <FavoriteProduct className={favoriteBlock ? 'expand' : ''}
                         onChange={onChange}
                         toggleFavoriteBlock={toggleFavoriteBlock}/>
    </div>
}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'staticTexts',
        'categories',
        'products',
        'shopProducts',
    ])
};
const mapDispatchToProps = {
    GetProducts,
    shopProduct,
    isOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
