import './HomeBlock.scss'
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {Link} from "react-router-dom";
import {getUrlWithLocal} from "../../../utils/getUrlWithLocal";


function HomeBlock(props) {
    const {staticTexts, block, reverse} = props

    return <div className={`homeBlock-wrapper ${reverse ? 'reverse' : ''}`}>
        <div className={'home-left-block'}>
            <div className={'home-imag'}>
                {/*<div className="inner-part">*/}
                    <img src={generateImageMediaUrl(block?.mediaMain?.path)} alt=""/>
                {/*</div>*/}
            </div>
        </div>
        <div className={'home-right-block'}>
            {reverse && <div className={'homeBlock-sub-title'}>
                {staticTexts?.homepage_block_sub_title}
            </div>}
            {<div className={`${reverse ? 'homeBlock-title': 'homeBlock-sub-title'}`}>
                {block?.title}
            </div>}
            <div className={'homeBlock-description'}>
                {block?.description}
            </div>
            <Link to={getUrlWithLocal('/about')} className={'homeBlock-more'}>
                {staticTexts?.homepage_more_info_btn}
            </Link>
        </div>
    </div>
}

export default HomeBlock
