import './Subscribe.scss'
import {InputGroup} from "../uiElements/inputGroup/InputGroup";
import {LoadingOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import isEmail from "validator/es/lib/isEmail";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {sendSubscribe} from "../../redux/actions";
import {connect} from "react-redux";

function Subscribe(props){
    const {staticTexts,requestLoading} = props
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);

    const getInputValues = (e) => {
        const {name, value} = e.target;
        setEmail(value);
        setError(false)
    };

    const sendSubscribe = () => {
        if (email && isEmail(email)) {
            props.sendSubscribe({email}).then(() => {
                setEmail(email)
            }, errorEmail => setError(!errorEmail)).finally(() => {
                setEmail('')
            })
        } else {
            setError(true)
        }
    }
    useEffect(()=> {
        setEmail("")
        setError(false)
    },[requestLoading])

    return <div className={'subscribe-wrapper'}>
        <div className={'subscribe-info'}>
            <div className={'subscribe-title'}>
                {staticTexts?.subscribe_title}
            </div>
            <div className={'subscribe-description'}>
                {staticTexts?.subscribe_description}
            </div>
        </div>
        <div className={'subscribe-input'}>
            <InputGroup
                inputType={'input'}
                type={"text"}
                value={email}
                error={error}
                name={'email'}
                placeholder={staticTexts?.homepage_contact_placeholder_email}
                maxLength={100}
                onChange={getInputValues}
            />

            <button className='sent-email'
                    onClick={() => {
                        if (!requestLoading && sendSubscribe) {
                            sendSubscribe()
                        }
                    }}>
                {staticTexts?.subscribe_btn}
                {
                    requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                        : ''
                }

            </button>
        </div>
    </div>
}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
    ])
};
const mapDispatchToProps = {
    sendSubscribe,
};
export default connect(mapStateToProps,mapDispatchToProps)(Subscribe);