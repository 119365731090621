import { _urlServices, request} from "../api";
import {SERVICE_CONST} from "../constants";

export const GetServices = () => {
    const requestData = {
        url: `${_urlServices}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: SERVICE_CONST.GET_SERVICES,
                    payload: data
                })
            })
    };
};

export const GetSelectedService = (slug) => {
    const requestData = {
        url: `${_urlServices}/`+slug,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: SERVICE_CONST.GET_SELECTED_SERVICE,
                    payload: data
                })
            })
    };
}