// import packages
import React from "react";

//Import styles
import "./subscribers.scss";
import {EllipseIcon, Line} from "../../../assets/images";

//Import Components

// Import utils


function Subscribers(props) {
    const {block} = props

    return <div className="subscribers-wrapper">
        <EllipseIcon className="subscribers-wrapper-ellipse-icon"/>
        <Line className="subscribers-wrapper-line"/>
        <div className="subscribers-wrapper-title">{block?.title}</div>
        <div className="subscribers-wrapper-description">
            {block?.description}
        </div>

        <div className="subscribers-wrapper-list">
            {
                !!block?.options?.length && block?.options?.map(item=> {
                    return <div className="list-item" key={item?.name}>
                        <div className="list-item-name">{item?.title}</div>
                        <div className="list-item-description">{item?.description}</div>
                    </div>
                })
            }
        </div>
    </div>
}

export default Subscribers
