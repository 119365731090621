import './MainBlcok.scss'
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {ArrowLinkIcon, ArrowsTopIcon, BottomCircleIcon, EllipseIcon} from "../../../assets/images";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {Link} from "react-router-dom";
import {getUrlWithLocal} from "../../../utils/getUrlWithLocal";

function MainBlock(props) {
    const {staticTexts, home} = props

    return <div className={'mainBlock-wrapper'}>
        <div className={'left-part'}>
            <div className="left-part-content">
                <p className={'mainBlock-sub-title'}>{staticTexts?.homepage_main_block_title}</p>
                <div className={'mainBlock-title'}>
                    {home?.title}
                </div>
                <p className={'mainBlock-description'}>
                    {home?.description}
                </p>
                <Link to={getUrlWithLocal('/contact')} className="mainBlock-btn">
                    {staticTexts?.btn_view_us} <ArrowLinkIcon/>
                </Link>
                <BottomCircleIcon className="mainBlock-bottom-circle"/>
            </div>
        </div>
        <div className={'right-part'}>
            <EllipseIcon className={'right-part-ellipse-icon'}/>
            <img src={generateImageMediaUrl(home?.mediaMain?.path)} alt=""/>
            {/**/}
            <div className="right-part-arrow">
                <ArrowsTopIcon/>
            </div>
            <div className="right-part-circle">
                <ArrowsTopIcon/>
            </div>
        </div>

        <EllipseIcon className={'mainBlock-wrapper-ellipse-icon'}/>
        <ArrowsTopIcon className="mainBlock-wrapper-arrow"/>
        <div className="mainBlock-wrapper-circle">
            <ArrowsTopIcon/>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MainBlock);
