import './FavoriteProduct.scss'
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import {FavoriteCloseIcon, TrashGrayIcon, TrashIcon} from "../../assets/images";
import {InputGroup} from "../uiElements/inputGroup/InputGroup";
import {LoadingOutlined} from "@ant-design/icons";
import React from "react";
import {useState} from "react";
import isEmail from "validator/es/lib/isEmail";
import {sendOrder, shopProduct} from "../../redux/actions";

function FavoriteProduct(props) {
    const {staticTexts,shopProducts,className,toggleFavoriteBlock,requestLoading,onChange} = props
    const [fieldsData,setFieldsData] = useState({
        name:'',
        phoneNumber:'',
        email:'',
        products:[],
    })
    const [error,setError] = useState({
        name:false,
        phoneNumber:false,
        email:false,
    })


    function validate() {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phoneNumber' && fieldsData.phoneNumber.length < 5 )){
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }

    function sendOrder() {
        const reqData = {
            ...fieldsData,
            products: shopProducts?.checkedId
        }
        if(validate()){
            props.sendOrder(reqData).then(() => {
            }).finally(() => {
                setFieldsData({
                    name:'',
                    phoneNumber:'',
                    email:'',
                    products: []
                })
                props.shopProduct({
                    count:null,
                    items:[],
                    checkedId:[],
                })
                toggleFavoriteBlock()
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            })
        }
    }

    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phoneNumber" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }

    return <div className={`favoriteProduct-wrapper ${className}`}>
        <div className={'order-block-wrapper'}>
            <div className={'favoriteProduct-header'}>
                <div className={'favoriteProduct-info'}>
                    <div className={'favoriteProduct-header-title'}>
                        {staticTexts?.product_page_order_title}
                    </div>
                    <div className={'favoriteProduct-header-description'}>
                        {staticTexts?.product_page_order_description}
                    </div>
                </div>
                <div className={'favoriteProduct-close'} onClick={toggleFavoriteBlock}>
                    <FavoriteCloseIcon/>
                </div>
            </div>
            {
                shopProducts?.itemsList?.length ? <div className={'favoriteProducts-block'}>
                    {
                        shopProducts?.itemsList?.map(item => {
                            return <div className={'favoriteProduct'}>
                                <div className={'favoriteProduct-title'}>
                                    {item?.title}
                                </div>
                                <div className={'favoriteProduct-trash'} >
                                    <TrashGrayIcon/>
                                </div>
                                <div className={'favoriteProduct-hover-trash'} onClick={(e) => onChange(e,item)}>
                                    <TrashIcon/>
                                </div>
                            </div>
                        })
                    }
                </div> : ''
            }
            <div className={'favoriteProduct-inputs-wrapper'}>
                <div className={'favoriteProducts-input-title'}>
                    {staticTexts?.service_modal_feedback_title}
                </div>
                <div className={'favoriteProducts-input-description'}>
                    {staticTexts?.service_modal_feedback_description}
                </div>
                <div className={'favoriteProducts-inputs'}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData?.name}
                        error={error?.name}
                        name={'name'}
                        placeholder={staticTexts?.about_us_feedback_name_placeholder}
                        label={staticTexts?.about_us_feedback_name_label}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData?.email}
                        error={error?.email}
                        name={'email'}
                        placeholder={staticTexts?.homepage_contact_placeholder_email}
                        label={staticTexts?.homepage_contact_placeholder_email}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData?.phoneNumber}
                        error={error?.phoneNumber}
                        name={'phoneNumber'}
                        label={staticTexts?.about_us_feedback_phoneNumber_label}
                        placeholder={staticTexts?.about_us_feedback_phoneNumber_placeholder}
                        maxLength={100}
                        onChange={getInputValues}
                    />

                    <button className='sent-email'
                            onClick={() => {
                                if (!requestLoading && sendOrder) {
                                    sendOrder()
                                }
                            }}>
                        {staticTexts?.about_us_feedback_btn}
                        {
                            requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                                : ''
                        }

                    </button>
                </div>
            </div>
        </div>
    </div>

}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
        'shopProducts',
    ])
};
const mapDispatchToProps = {
    sendOrder,
    shopProduct
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteProduct);