// Import packages
import React from "react";
import {Navigate,Routes, Route, useLocation} from "react-router-dom";
import Layout from "../containers/Layout";

// Import utils
import {history} from "../configs/history";

// Import pages
import Homepage from "../containers/homepage/Homepage";
import Services from "../containers/service/Services";
import About from "../containers/about/About";
import Info from "../containers/info/Info";
import Contact from "../containers/contact/Contact";
import Product from "../containers/product/Product";
import {store} from "../redux/store";


const locales = ['hy', 'en', 'ru']

export default function RoutesBlocks() {
    const location = useLocation()
    const path = location?.pathname
    const {selectedLanguage, languages} = store.getState() || {}
    const selectedCode = selectedLanguage?.code || 'hy'
    let locale = path.split('/')[1]
    console.log(locale,'locale')
    if (!location || path === '/') {
        history.push(`/${selectedCode}`)
    }

    return <Layout>
        <Routes>
            <Route render={() => <Navigate to={`/${locale}`}  replace/>}/>
            <Route path={`/${locale}`} element={<Homepage/>}/>
            <Route path={`/${locale}/services`} element={<Services/>}/>
            <Route path={`/${locale}/about`} element={<About/>}/>
            <Route path={`/${locale}/info`} element={<Info/>}/>
            <Route path={`/${locale}/contact`} element={<Contact/>}/>
            <Route path={`/${locale}/products`} element={<Product/>}/>
        </Routes>
    </Layout>
}
