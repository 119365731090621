import {combineReducers} from "redux";

// import reducers
import general from './general';
import service from './service'
import utils from "./utils";
import contact from "./contact"
import about from "./about";

export default combineReducers({
    general,
    utils,
    service,
    contact,
    about,
});
