// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

//Import assets
import './info.scss'

//Import components

//Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetInfo} from "../../redux/actions";
import {generateFileMediaUrl, generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {bg_decorative, DownloadIcon, ParkIcon, PDFIcon} from "../../assets/images";


function Info(props) {
    const {staticTexts, info} = props;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        props.GetInfo()
    }, [])


    function downloadPresentation(pdf) {

        if (pdf) {
            fetch(generateImageMediaUrl(pdf?.path))
                .then(response => {
                    response.blob().then(blob => {
                        let a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = `${pdf.name}`;
                        a.click();
                    });
                });
        }
    }

    function navigateTo(link) {
        window.open(link)
    }

    return <div className={'info-wrapper'}>
        <img src={bg_decorative} className={'info-wrapper-bg'} alt=""/>
        <div className={'info-sub-title'}>{staticTexts?.info_sub_title}</div>
        <div className={'info-title'}>{info?.title}</div>
        <div className={'info-description'}>{info?.description}</div>

        {!!info?.blocks?.[0] && <div className={'blocks-first'}>
            {!!info?.blocks?.[0]?.options?.length && <div className="options-list">
                {
                    info?.blocks?.[0]?.options?.map(item => {
                        return <div className="option-item" key={item?.id} onClick={()=>navigateTo(item?.link)}>
                            <ParkIcon className="option-item-icon"/>
                            <div className="option-item-title">{item?.title}</div>
                        </div>
                    })
                }
            </div>}
        </div>}
        <div className="blocks-second">
            <div className="blocks-second-left">
                <div className="block-title">{info?.blocks?.[1]?.title}</div>
                <div className="block-description">
                    {info?.blocks?.[1]?.description}
                    {
                        info?.blocks?.[1]?.options?.map((item, index) => {
                            return <div className="option-item" key={index}>
                                <span>{item?.title}</span>&nbsp;-&nbsp;{item?.description}
                            </div>
                        })
                    }
                </div>
                <div className="block-title">{staticTexts?.info_block2_pdf_title}</div>
                <div className="block-link" id={'downloadLink'} onClick={() => {downloadPresentation(info?.blocks?.[1]?.pdfs[0])}}>
                    <span><DownloadIcon/>{staticTexts?.info_block2_pdf_btn}</span>
                </div>
            </div>
            <div className="blocks-second-right">
                <div className="block-inner">
                    <div className="block-title">{info?.blocks?.[3]?.title}</div>
                    {
                        !!info?.blocks?.[3]?.options?.length && info?.blocks?.[3]?.options?.map((item, index) => {
                            return  <div className="block-link" key={index} onClick={() => {downloadPresentation(item?.pdf)}}>
                                <DownloadIcon/>
                                <span>{item?.title}</span>
                            </div>
                        })
                    }
                    {/*<div className="block-link">*/}
                    {/*    <DownloadIcon/>*/}
                    {/*    <span>{staticTexts?.info_block2_download_btn1}</span>*/}
                    {/*</div>*/}
                    {/*<div className="block-link">*/}
                    {/*    <DownloadIcon/>*/}
                    {/*    <span>{staticTexts?.info_block2_download_btn2}</span>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>

        {!!info?.blocks?.[2] && <div className={'blocks-third'}>
            <div className="block-title">{info?.blocks?.[2]?.title}</div>
            <div className="block-description">{info?.blocks?.[2]?.description}</div>
            {!!info?.blocks?.[2]?.options?.length && <div className="options-list">
                {
                    info?.blocks?.[2]?.options?.map(item => {
                        return <div className="option-item" key={item?.id} onClick={()=>navigateTo(item?.pdf && generateFileMediaUrl(item?.pdf?.path))}>
                            <PDFIcon className="option-item-icon"/>
                            <div className="option-item-title">{item?.description}</div>
                        </div>
                    })
                }
            </div>}
        </div>}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'staticTexts',
        'info',
    ])
};
const mapDispatchToProps = {
    GetInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Info);
