// import packages
import React from "react";

//Import styles
import "./HomePartners.scss";
import {partner_1, partner_2, partner_3} from "../../../assets/images";

//Import Components

// Import utils


function HomePartners(props) {
    const {} = props

    const partners = [partner_1, partner_2, partner_3]

    return !!partners?.length && <div className="home-partners-wrapper">
        <div className="partners-list-wrapper">
            {partners?.map(item => {
                return <div className="partner-item" key={item}>
                        <div className="partner-content">
                            <img src={item} className="fade-image"/>
                        </div>
                    </div>
            })}
        </div>
    </div>
}

export default HomePartners
