import { ABOUT_CONST } from "../constants";

export const initialState = {
  about: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ABOUT_CONST.GET_ABOUT:
      return {
        ...state,
        about: action.payload,
      };
    default:
      return state;
  }
};
