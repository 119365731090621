import { SERVICE_CONST } from "../constants";

export const initialState = {
  services: [],
  selectedService: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_CONST.GET_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case SERVICE_CONST.GET_SELECTED_SERVICE:
      return {
        ...state,
        selectedService: action.payload,
      };
    default:
      return state;
  }
};
