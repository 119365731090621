import {CONTACT_CONST} from "../constants";


export const initialState = {
    contacts: [],
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case CONTACT_CONST.GET_CONTACTS:
            return {
                ...state,
                contacts: action.payload
            };
        default:
            return state;
    }
}