import React from "react";
import {connect} from "react-redux";
import Slider from "react-slick";
import {useEffect, useRef} from "react";

import './about.scss'

import VisibilitySensor from "react-visibility-sensor";
import {CountUp} from "use-count-up";
import {bg_decorative} from "../../assets/images";

import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetAbout, SendCall} from "../../redux/actions";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";

function About(props) {
    const {staticTexts, about, partners} = props


    let sliderRef = useRef(null);
    const settings = {
        className: "slider variable-width",
        dots: false,
        arrows: false,
        autoplay:true,
        infinite:true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        ref: partner => (sliderRef = partner),
    };

    useEffect(() => {
        props.GetAbout()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])


    return <div className={'about-wrapper'}>
        <img src={bg_decorative} className={'about-wrapper-bg'} alt=""/>
        <div className={'about-header'}>
            <div className={'about-sub-title'}>{staticTexts?.about_sub_title}</div>
            <div className={'about-title'}>{about?.title}</div>
            <div className={'about-description'}>{about?.description}</div>
        </div>
        {about?.blocks?.[0] && <div className={'blocks-first'}>
            <img src={generateImageMediaUrl(about?.blocks?.[0]?.mediaMain?.path)}
                 className={'blocks-first-img'} alt=""/>
            <div className="inner-part">
                <div className="overlay-black"/>
                {
                    about?.blocks?.[0]?.options?.slice(0, 3)?.map(item => {
                        return <VisibilitySensor key={item?.id}>{({isVisible}) => {
                            return (
                                <div className="option-item">
                                    <div className="overlay"/>
                                    <div className="option-item-title">
                                        <CountUp isCounting={isVisible} start={0}
                                                 end={Number(item?.title)}
                                                 duration={1} delay={4} easing="linear"/>
                                        +
                                    </div>
                                    <div className="option-item-description">{item?.description}</div>
                                </div>
                            );
                        }}</VisibilitySensor>
                    })
                }
            </div>
        </div>}

        <div className={'about-partners'}>
            <div className="about-partners-text">{staticTexts?.homepage_partners_list}</div>
            <Slider {...settings}>
                {partners?.map(item => {
                    return <div key={item} style={{
                        width: window?.innerWidth < 700 ? 132 : 192
                    }}>
                        <div className="partner-item" key={item.id}>
                            <div className="partner-content">
                                <img src={generateImageMediaUrl(item?.mediaMain?.path)} className="fade-image"
                                     title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                     alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                            </div>
                        </div>
                    </div>
                })}
            </Slider>
        </div>


        {about?.blocks?.[1] && <div className={'blocks-second'}>
            <div className="blocks-second-sub-title">{staticTexts?.about_block1_sub_title}</div>
            <div className="blocks-second-title">{about?.blocks?.[1]?.title}</div>
            <div className="blocks-second-description">{about?.blocks?.[1]?.description}</div>
            <div className="options-list">
                {
                    about?.blocks?.[1]?.options?.map(item => {
                        return <div className="option-item" key={item?.id}>
                            <div className="option-item-img">
                                <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                     title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                     alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                            </div>

                            <div className="option-item-title">{item?.title}</div>
                            <div className="option-item-description">{item?.description}</div>
                        </div>
                    })
                }
            </div>
        </div>}

        {about?.blocks?.[2] && <div className={'blocks-third'}>
            {/*<div className="blocks-second-sub-title">Հիբրիդ Սոլյուշնս ընկերություն</div>*/}
            <div className="blocks-second-title">{about?.blocks?.[2]?.title}</div>
            <div className="blocks-second-description">{about?.blocks?.[2]?.description}</div>
            <div className="options-list">
                {
                    about?.blocks?.[2]?.options?.slice(0, 4)?.map(item => {
                        return <div className="option-item" key={item?.id}>
                            <div className="option-item-img">
                                <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                     title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                     alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                            </div>
                            <div className="option-item-title">{item?.title}</div>
                            <div className="option-item-description">{item?.description}</div>
                        </div>
                    })
                }
            </div>
        </div>}
    </div>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'requestLoading',
        'staticTexts',
        'services',
        'about',
        'partners',
    ])
};

const mapDispatchToProps = {
    GetAbout,
    SendCall
};


export default connect(mapStateToProps, mapDispatchToProps)(About);
