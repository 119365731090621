import { _urlAbout, request } from "../api";
import { ABOUT_CONST } from "../constants";

export const GetAbout = () => {
  const requestData = {
    url: `${_urlAbout}`,
    method: "GET",
    languageFlag: true,
  };

  return (dispatch) => {
    return request(requestData).then((res) => {
      const data = res && res.data;
      data &&
        dispatch({
          type: ABOUT_CONST.GET_ABOUT,
          payload: data,
        });
    });
  };
};
