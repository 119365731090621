// import packages
import React, {useRef} from "react";
import Slider from "react-slick";

//Import styles
import "./contracts.scss";
import {EllipseIcon} from "../../../assets/images";

//Import Components
import VisibilitySensor from "react-visibility-sensor";
import {CountUp} from "use-count-up";

// Import utils
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";

function Contracts(props) {
    const {block, staticTexts, partners} = props

    let sliderRef = useRef(null);
    const settings = {
        className: "slider variable-width",
        dots: false,
        arrows: false,
        autoplay:true,
        infinite:true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        ref: partner => (sliderRef = partner),
    };

    return <div className="contracts-wrapper">
        <div className="contracts-wrapper-top">
            <EllipseIcon className="contracts-wrapper-top-ellipse-icon"/>
            <div className="contracts-wrapper-title">{block?.title}</div>
            <div className="contracts-wrapper-description">
                {block?.description}
            </div>
            {block?.options?.length > 2 && <div className="contracts-wrapper-counts">
                <VisibilitySensor>{({isVisible}) => {
                    return (
                        <div className="counts-item">
                                <span>
                                <CountUp isCounting={isVisible} start={0}
                                         end={Number(block?.options[0]?.title)}
                                         duration={1} delay={4} easing="linear"/>
                                +
                                    </span>
                            <span>{block?.options[0]?.description}</span>
                        </div>
                    );
                }}</VisibilitySensor>

                <VisibilitySensor>{({isVisible}) => {
                    return (
                        <div className="counts-item">
                                <span>
                                <CountUp isCounting={isVisible} start={0}
                                         end={Number(block?.options[1]?.title)}
                                         duration={1} delay={4} easing="linear"/>
                                +
                                    </span>
                            <span>{block?.options[1]?.description}</span>
                        </div>
                    );
                }}</VisibilitySensor>
                <div className="counts-item">
                    <span>{block?.options[2]?.title}</span>
                    <span>{block?.options[2]?.description}</span>
                </div>
                <div className="counts-item">
                    <span>{block?.options[3]?.title}</span>
                    <span>{block?.options[3]?.description}</span>
                </div>
            </div>}
            <div className="contracts-wrapper-text">{staticTexts?.homepage_partners_list}</div>
        </div>
        {!!partners?.length && <div className="contracts-wrapper-partners">
            <div className="partners-list-wrapper">
                {
                    <Slider {...settings}>
                        {partners?.map(item => {
                            return <div key={item} style={{
                                width: window?.innerWidth < 700 ? 132 : 192
                            }}>
                                <div className="partner-item" key={item.id}>
                                    <div className="partner-content">
                                        <img src={generateImageMediaUrl(item?.mediaMain?.path)} className="fade-image"
                                             title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                             alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                    </div>
                                </div>
                            </div>
                        })}
                    </Slider>
                }
            </div>
        </div>}
    </div>
}

export default Contracts
