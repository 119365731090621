export const GENERAL_CONSTS = {
    GET_STATIC_TEXTS: "GET_STATIC_TEXTS",
    GET_LANGUAGES: "GET_LANGUAGES",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    SEND_SUBSCRIBERS: "SEND_SUBSCRIBERS",
    GET_PARTNERS: "GET_PARTNERS",
    GET_SPONSORS: "GET_SPONSORS",
    GET_INFO: "GET_INFO",
    GET_PRODUCT: "GET_PRODUCT",
    GET_PROJECT:"GET_PROJECT",
    GET_HOME:"GET_HOME",
    GET_LAST_NEWS:'GET_LAST_NEWS',
    SHOP_PRODUCT:'SHOP_PRODUCT',
    SEND_ORDER: "SEND_ORDER",
    IS_OPEN: "IS_OPEN",
};

