import {_urlCall, _urlContacts, request} from "../api";
import {CONTACT_CONST, UTIL_CONSTS} from "../constants";


export const GetContacts = () => {
    const requestData = {
        url: `${_urlContacts}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: CONTACT_CONST.GET_CONTACTS,
                    payload: data
                })
            })
    };
};

export const SendEmail = (data) => {
    const requestData = {
        url: `${_urlContacts}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type:UTIL_CONSTS.END_LOADING})
        })
    }
};

export const SendCall = (data) => {
    const requestData = {
        url: `${_urlCall}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type:UTIL_CONSTS.END_LOADING})
        })
    }
};