import './map.scss'
import {Link} from "react-router-dom";
import {MapImg, PlayIcon} from "../../../assets/images";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import React from "react";
import {getUrlWithLocal} from "../../../utils/getUrlWithLocal";


function MapBlock(props) {
    const {staticTexts, block} = props

    return <div className={'map-wrapper'}>
        <div className={'map-img'}>
            <img src={generateImageMediaUrl(block?.mediaMain?.path)} className="fade-image"
                 title={block?.mediaMain?.title ? block?.mediaMain?.title : ''}
                 alt={block?.mediaMain?.altAttribute ? block?.mediaMain?.altAttribute : ''}/>
        </div>
        <div className={'map-wrapper-right'}>
            <PlayIcon className={'map-wrapper-svg'}/>
            <div className={'map-wrapper-title'}>
                {block?.title}
            </div>
            <div className={'map-wrapper-description'}>
                {block?.description}
            </div>
            <Link to={getUrlWithLocal('/about')} className={'map-wrapper-more'}>
                {staticTexts?.homepage_more_info_btn}
            </Link>
        </div>
    </div>
}

export default MapBlock
