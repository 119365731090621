// import packages
import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import isEmail from "validator/es/lib/isEmail";

//Import assets
import './Contact.scss';

//Import Components

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {SendEmail} from "../../redux/actions";
import {LoadingOutlined} from "@ant-design/icons";
import {ArrowLinkIcon, bg_1, bg_2, bg_decorative, ChatIcon, MarkerIcon, PhoneIcon} from "../../assets/images";
import {InputGroup} from "../../components/uiElements/inputGroup/InputGroup";

function Contact(props) {
    const {staticTexts, contacts, requestLoading} = props
    const [fieldsData, setFieldsData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        message: '',
    })
    const [error, setError] = useState({
        firstname: false,
        lastname: false,
        email: false,
        phone: false,
        message: false,
    })
    const [typesList, setTypesList] = useState({
        'PHYSICAL': {
            key: 'PHYSICAL',
            name: staticTexts?.contacts_page_service_physical,
            checked: false
        },
        'LEGAL_PERSON': {
            key: 'LEGAL_PERSON',
            name: staticTexts?.contacts_page_service_person,
            checked: false
        },
        'INFORMATION_SPREAD': {
            key: 'INFORMATION_SPREAD',
            name: staticTexts?.contacts_page_service_information,
            checked: false
        }
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    function validate() {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phone' && fieldsData.phone.length < 5)) {
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }

    function sendVisit() {
        if (validate()) {
            let reqData = {...fieldsData}
            const types = Object.values(typesList)?.filter(i => i?.checked)
            reqData.type = types.map(i => i.name)
            // console.log('types---------', reqData);
            props.SendEmail(fieldsData).finally(() => {
                setFieldsData({
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: '',
                    message: '',
                })
            })
        }
    }

    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }

    function getTypeValues(e, key) {
        setTypesList({
            ...typesList,
            [key]: {
                ...typesList[key],
                checked: !typesList[key]?.checked
            }
        })
    }

    return <div className={'contacts-page'}>
        <div className="contacts-page-header">
            <img src={bg_decorative} className={'contacts-page-bg'} alt=""/>
            <div className={'contacts-page-title'}>
                {staticTexts?.contact_title}
            </div>
            <div className={'contacts-page-description'}>
                {staticTexts?.contact_description}
            </div>
        </div>
        <div className="top-part">
            <div className="image-wrapper">
                <img src={bg_2} alt="" className="first"/>
                <img src={bg_1} alt="" className="second"/>
            </div>
            <div className="form-wrapper">
                <div className={'contacts-page-title'}>
                    {staticTexts?.contact_title}
                </div>
                <div className={'contacts-page-description'}>
                    {staticTexts?.contact_description}
                </div>

                <div className="row">
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.firstname}
                        error={error.firstname}
                        name={'firstname'}
                        placeholder={staticTexts?.contacts_page_placeholder_name}
                        label={staticTexts?.contacts_page_label_name}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.lastname}
                        error={error.lastname}
                        name={'lastname'}
                        placeholder={staticTexts?.contacts_page_placeholder_lastName}
                        label={staticTexts?.contacts_page_label_lastName}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                </div>
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.email}
                    error={error.email}
                    name={'email'}
                    placeholder={staticTexts?.contacts_page_placeholder_email}
                    label={staticTexts?.contacts_page_label_email}
                    maxLength={100}
                    onChange={getInputValues}
                />
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.phone}
                    error={error.email}
                    name={'phone'}
                    placeholder={staticTexts?.contacts_page_label_phoneNumber}
                    label={staticTexts?.contacts_page_label_phoneNumber}
                    maxLength={100}
                    onChange={getInputValues}
                />
                <InputGroup
                    inputType={'textarea'}
                    type={"text"}
                    value={fieldsData.message}
                    error={error.message}
                    name={'message'}
                    placeholder={staticTexts?.contacts_page_placeholder_message}
                    label={staticTexts?.contacts_page_label_message}
                    // maxLength={100}
                    onChange={getInputValues}
                />

                <div className="form-wrapper-services">
                    <p>{staticTexts?.contacts_page_services}</p>

                    {
                        Object.keys(typesList)?.map(item => {
                            return <div className="type-item" key={item}>
                                <div className={`custom-checkbox ${typesList[item]?.checked ? 'checkbox' : ''}`}
                                     onClick={(e) => getTypeValues(e, item)}>

                                </div>
                                <span>{typesList[item]?.name}</span>
                            </div>
                        })
                    }
                </div>

                <button className='sent-email'
                        onClick={() => {
                            if (!requestLoading && sendVisit) {
                                sendVisit()
                            }
                        }}>
                    {staticTexts?.contacts_page_btn_send}
                    <ArrowLinkIcon/>
                    {
                        requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                            : ''
                    }

                </button>

            </div>
        </div>
        <div className="bottom-part">
            <div className={'contact-info'}>
                <a href={`mailto:${contacts?.email}`} className={'info-icon'}><ChatIcon/></a>
                <div className={'info-title'}>
                    {staticTexts?.contact_email_title}
                </div>
                <div className={'info-desc'}>
                    {staticTexts?.contact_email_desc}
                </div>
                <a href={`mailto:${contacts?.email}`} className={'info-val'}>
                    {contacts?.email}
                </a>
            </div>
            <div className={'contact-info'}>
                <div className={'info-icon'}>
                    <MarkerIcon/>
                </div>
                <div className={'info-title'}>
                    {staticTexts?.contact_address_title}
                </div>
                <div className={'info-desc'}>
                    {staticTexts?.contact_address_desc}
                </div>
                <div className={'info-val'}>
                    {contacts?.address}
                </div>
            </div>
            <div className={'contact-info'}>
                <a href={`tel:${contacts?.phoneNumber}`} className={'info-icon'}>
                    <PhoneIcon/>
                </a>
                <div className={'info-title'}>
                    {staticTexts?.contact_number_title}
                </div>
                <div className={'info-desc'}>
                    {staticTexts?.contact_number_desc}
                </div>
                <a href={`tel:${contacts?.phoneNumber}`} className={'info-val'}>
                    {contacts?.phoneNumber}
                </a>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    SendEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
