import './ProductCard.scss'
import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import React from "react";
import { Checkbox } from 'antd';
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {shopProduct} from "../../../../redux/actions";
import {connect} from "react-redux";

function ProductCard(props){
    const {product,onChange,shopProducts,} = props


    return <div className={'productCard-wrapper'}>
        <div className={'productCard-checkbox'}>
            <Checkbox checked={shopProducts?.checkedId?.includes(product?.id)} onChange={(item) => {
                onChange(item, product)
            }}/>
        </div>
        <div className={'productCard-img'}>
            <img src={generateImageMediaUrl(product?.mediaMain?.path)}/>
        </div>
        <div className={'productCard-info'}>
            <div className={'productCard-title'}>
                {product?.title}
            </div>
            <div className={'productCard-description'} dangerouslySetInnerHTML={{__html: product?.description}}/>
        </div>
    </div>
}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'shopProducts',
    ])
};
const mapDispatchToProps = {
    shopProduct
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
